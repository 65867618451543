// Event ID -> translation suffix
const AuditLogEventsNames = {
  'f40f737e-aba2-4784-9b6b-1667dbebaf87': 'createPartner',
  'f13d0e81-2018-4c24-a9da-8be0497f214c': 'createPartnerForChild',
  '04a9bbff-56cf-4f89-93ce-fac58c932a54': 'createPartnerByParent',
  '6bc0f6eb-9458-40a1-aeda-6b08cd185347': 'updatePartner',
  '5301bb7e-f2a2-41ca-b000-d63d9516f56e': 'updatePartnerForChild',
  '4826e310-269d-4efe-b082-385569a0129d': 'updatePartnerByParent',
  '61e058e7-f140-48fe-8878-fd034d8617ce': 'getPartnerById',
  '96b26f83-4aac-4b6f-97dd-e910d561cb7d': 'getPartnerByIdForChild',
  '0cd3c49a-6ea8-47f5-b967-e8bcca6bb84e': 'getPartnerHistoricLicenseData',
  'cdbece6c-892e-4ed1-aef6-6908ad0d0d1a': 'getPartnerHistoricLicenseDataForChild',
  '694601b4-cd7b-499b-a795-bda4e766ce44': 'createPartnerUser',
  '891be051-e3b1-4f68-89f5-427342829715': 'createPartnerUserForChild',
  '728a4603-dd2d-46a4-93a3-c6a474aa6549': 'createPartnerUserByParent',
  'ebbc4f74-ba99-485a-b51d-1bebc691db15': 'updatePartnerUser',
  'ad4f0d41-f037-4a5b-95ad-260bde2adff9': 'updatePartnerUserForChild',
  '58cd222a-8273-41b9-8986-f7002ac05577': 'updatePartnerUserByParent',
  '7f64bdcb-fa34-4909-8c66-a8c7acfa8395': 'changeRole',
  '9aaad880-e51c-4d3a-b2de-f106a6bf4e8e': 'changeRoleForChild',
  'd7378b23-2acc-430b-a18b-5c35e9ad3e0c': 'changeRoleByParent',
  '50913fce-e253-4a7b-9250-231f1e0f2796': 'deletePartnerUser',
  'db1d250b-7497-47fe-add8-8ef14318eace': 'deletePartnerUserForChild',
  'fe20fe94-fa85-4cf4-a66e-2ae3d750d365': 'deletePartnerUserByParent',
  '05caf21e-98d1-4781-8998-938523530a89': 'getPartnerUserById',
  '9b0e160e-97b4-489f-8e65-ad29dd121f63': 'getPartnerUserByIdForChild',
  '1c93f019-29be-4636-b21c-39bf20025da8': 'resetMasterPassword',
  '794ff173-24b6-4f98-bd16-825e8c6e4587': 'resetMasterPasswordForChild',
  'be8ada73-97b8-4689-bf65-ae09e473847f': 'resetMasterPasswordByParent',
  'b31337e5-93cd-4f3d-b3de-4f742ada9af1': 'resetTwoFactor',
  'c1dbe537-c38b-4c1e-a923-57f097d98aef': 'resetTwoFactorForChild',
  'ee76f097-6ac7-4ad6-9a19-d69fa11af376': 'resetTwoFactorByParent',
  '18fc4357-d2c9-4f0c-a440-1a1cd72408f3': 'updateUserProfile',
  'a5a1e3a3-72fc-43ea-9c92-7440c980660a': 'createContactPerson',
  '8fb14bec-7ab1-4e31-a5aa-60ac7ac0de30': 'createContactPersonForChild',
  'f4d6ea9d-f23c-4d75-af7f-8724f5288939': 'createContactPersonByParent',
  '578a8fca-7adf-4b98-9dfc-496d61a78379': 'updateContactPerson',
  '5b04d1b4-01d2-49cb-85ed-ab69f07e3968': 'updateContactPersonForChild',
  '8c0b2470-16d2-4300-b093-03b21f3c74dd': 'updateContactPersonByParent',
  'd0650d91-b3ab-43f1-b1e8-dc45cb33ee0d': 'getContactPersonById',
  '99f95531-0492-4811-98a6-46bcd7f5c3d4': 'getContactPersonByIdForChild',
  '1378180b-6e17-4295-9fd5-c0a01f265c01': 'createPartnerOrganization',
  'fe357a9d-7a33-4cc1-b6e9-a23d6f32ce5d': 'createPartnerOrganizationForChild',
  'c1fe79dd-8f82-4fab-8c98-e290e56dfa69': 'createPartnerOrganizationByParent',
  'f0b51bb6-50a6-452f-b6aa-5841e4892acb': 'updatePartnerOrganization',
  'c595fa4e-ea11-40fa-b0f4-7ad9d58b78b8': 'updatePartnerOrganizationForChild',
  '716c4d43-c12a-4510-8cfe-a29a9785443c': 'updatePartnerOrganizationByParent',
  '1d7351a4-9983-4c37-9fab-0ad2bf8c6e94': 'getPartnerOrganizationById',
  '2d74aff2-55c8-4979-b714-9594903e42e5': 'getPartnerOrganizationByIdForChild',
  '91956622-f48e-45fa-9096-65dbb56f8e23': 'getPartnerOrganizationHistoricLicenseData',
  'fd8aa34f-d154-42ba-a98a-87206c0d1c98': 'getPartnerOrganizationHistoricLicenseDataForChild',
  'ddbdb6d6-0f05-4ea5-8085-b0b3a0bbe16a': 'getEmployeeAccountById',
  '708983d6-f786-4619-9193-2cde3dc32a1f': 'getEmployeeAccountByIdForChild',
  '34195f90-b427-4ac1-9a56-cb415882b44b': 'stepTwoLogin',
  '18e5124b-5b65-4475-b104-feb72195c4c4': 'changeMasterPassword',
  '871fe788-ffcf-4ea1-864c-23ca7c580829': 'generateTwoFactorSecret',
  '74fb4180-e74d-409e-b249-babec563a14a': 'setTwoFactor',
  'a29257ba-066b-4706-a7b5-ef453db2b1f5': 'twoFactorLogin',
  '89042c39-408d-4880-8f50-b23403b36b17': 'createGroup',
  '1749c08d-b2b9-4bdb-951f-d4647732c604': 'createGroupForChild',
  'f517dc6b-8c72-405c-8a03-83292742ff57': 'createGroupByParent',
  '94a6d51a-99a4-4b10-87ef-e0c286c8751f': 'updateGroup',
  '5bea2478-14f0-4fc8-8259-7523dfb1a10e': 'updateGroupForChild',
  'b6dffd5d-44b8-4c75-ab92-902f07c57beb': 'updateGroupByParent',
  '33f47398-0fe0-48b8-8596-02a3b734721b': 'deleteGroup',
  '1d8a3caf-a55c-4e78-a7d8-974f9fe09a69': 'deleteGroupForChild',
  '7d21d2ee-b5d7-46d9-8d67-4d058f407f68': 'deletedGroupForChild',
  'a3809896-0f09-4ca7-b4bd-8bd8af72f306': 'getGroupById',
  '1fd10981-9e62-4665-823e-482582435313': 'getGroupByIdForChild',
  '9057b884-c4dc-4f11-a90f-964a09be458c': 'addUsersToGroup',
  '8d6c4014-aedf-4a80-a13d-0195874e44b1': 'addUsersToGroupForChild',
  '81eaded3-50f5-4693-89c7-95ecf1c19d25': 'addUsersToGroupByParent',
  '58bbf315-c6d5-4894-8222-24d2dcd7e321': 'removeUsersFromGroup',
  'c43a692c-c1ca-40a0-96d4-f0de187d96c4': 'removeUsersFromGroupForChild',
  'b4c34802-a383-4b77-8207-15faa9d5b85b': 'removeUsersFromGroupByParent',
  'd11fbc7b-6bd2-433e-a108-6badc74a74d9': 'addOrganizationsToGroup',
  '5467b4f3-f553-4317-8970-dd4b240a665e': 'addOrganizationsToGroupForChild',
  '5e4e08aa-5bea-41ce-9541-0609135a1318': 'addOrganizationsToGroupByParent',
  'cdfeda9d-d167-40bc-821b-ab7cf1f4f912': 'removeOrganizationsFromGroup',
  '3798f8bb-d06f-4e14-84f6-ab8738614d29': 'removeOrganizationsFromGroupForChild',
  '331677da-9e1c-4d68-8bf5-dbe8ea12ff1c': 'removeOrganizationsFromGroupByParent',
  'f3dd51df-4154-4235-9595-6128bb530fa4': 'createMasterPasswordAdmin',
  'e08016c7-90b0-4a91-94eb-374f5dcb56c1': 'createMasterPasswordEmployee',
  '33db81e2-9737-4922-ac39-81d0401c93ef': 'userLoggedInExtension',
  'f9a16cea-9c03-4f37-b8d7-f6ad58135122': 'userLoggedOutExtension',
  'dc327047-6d17-46ac-941a-12f4e1a641bf': 'adminLoggedInOP',
  'b61bc15e-530d-41bf-a774-fc6ae2d3c271': 'adminLoggedOutOP',
  '93398430-8b07-4284-acad-17be00ca72d1': 'createLoginMobile',
  'fbeb014c-895b-40aa-9025-e5728d7f7aaf': 'updateLoginExtension',
  'd91defea-fd9c-4c22-9cc4-49c6aff7a677': 'deleteVaultOP',
  'a08d67fb-4b77-48bb-af81-22b0563f5c01': 'pairedWithOrganizationPortalMobile',
  '2da725cd-d036-4d5f-ad9d-c9b5664be9d4': 'approvedRequestMobile',
  'e35dc87c-99c1-4189-8e74-9d88e142f677': 'deniedRequestMobile',
  'ee1dae7f-2cbf-4e58-99b1-10399591fe04': 'unlinkLoginFromEmployeeRequestedOP',
  '7bee81d2-bd71-4070-809b-31731dbf77d8': 'unlinkLoginFromUserMobile',
  '74413876-211d-4f42-be31-ed35aa6556af': 'updateUserNameOP',
  'ff185791-6375-4cd0-913a-c43733139e3d': 'updateUserNameMobile',
  '319c8a05-abd5-4b13-b51f-d6198578e61c': 'archiveEmployeeOP',
  '169031e9-e65e-48e8-9f44-a775798d310e': 'createLoginForGroupMobile',
  '5aa1a902-8c3f-49b1-865b-e3a41aacf9d7': 'unlinkLoginFromGroupMobile',
  '4361dd86-0f70-47e0-91de-071a81d49834': 'removeEmployeeFromGroupOP',
  '718252d7-9c34-453d-9428-f2c4fb59dce3': 'createSecureNoteForEmployeeRequestedOP',
  '8c4d60b9-df82-4d3b-9858-fba8ff6f8935': 'createSecureNoteMobile',
  '24662143-066f-4325-823f-8b1b6e1a45bf': 'createSecureNoteForGroupMobile',
  'b3c7e1cb-91cb-4f52-b8bb-aa38685d5a76': 'createGroupOP',
  '9fadaac4-7099-44c5-aac4-3e3fd624892b': 'updateGroupNameOP',
  '9232fa1e-ecd3-453e-95cb-6c58ee6be73f': 'updateGroupDescriptionOP',
  'b768ede7-ad08-4b39-8b7f-a25362aa261c': 'removeGroupOP',
  '39f6bc3e-2d8b-4236-b24b-be28d77e771a': 'updateAdminNameOP',
  '7ae2c772-8b1d-4948-8bcf-9920f1c1fe86': 'updateAdminEmailOP',
  'b782df94-89ab-41cc-b018-05e97fdbe355': 'changeLanguageOP',
  'b72459d9-79d7-4ca1-827f-fa9d143ea6cb': 'updateLoginDetailsForEmployeeMobile',
  'ed29b668-6c88-4366-a5c2-b640026d166b': 'updateLoginDetailsForGroupMobile',
  '50230b3a-066b-4dff-bb9b-d4541a797063': 'updateLoginPasswordForEmployeeMobile',
  'f5488a5e-afb2-44f0-8c5d-48ae6ae6cd51': 'updateLoginPasswordForGroupMobile',
  '0983c644-a9e2-4170-a199-7547064bbf12': 'updateSecureNoteNameForEmployeeMobile',
  '8e000b98-b49a-4233-bc45-a25ecbf4516f': 'updateSecureNoteNameForGroupMobile',
  '9f08afe8-2401-4d3a-97f6-8945e0982afe': 'updateSecureNoteSecureDataForEmployeeMobile',
  'efd7a9f3-a741-4a2a-b8bf-b30ace5372a3': 'updateSecureNoteSecureDataForGroupMobile',
  '5e2830f4-b05b-4e89-a65d-9ce22dd2cc64': 'updateCreditCardDetailsForEmployeeMobile',
  '5835657e-60c7-4a8f-ae3d-5b124d89fd83': 'updateCreditCardDetailsForGroupMobile',
  'f7f122b0-bdf9-469d-b44d-c677a993057b': 'updateCreditCardSecureDataForEmployeeMobile',
  '7afbd040-f302-49c7-a65c-95aef4c722e2': 'updateCreditCardSecureDataForGroupMobile',
  '4ed5b3be-d1b1-4447-af32-9ae2f1c090c7': 'activatedAccountExtension',
  '247302b7-4aa1-4377-af97-1a71573ce5ed': 'signedIntoMobileApp',
  '124de9be-7313-49f5-b2e0-0b4a541dee58': 'loggedOutFromMobileApp',
  'ad5de08d-5c38-4a39-aef4-56689c336b24': 'pairedWithExtensionMobile',
  '3dee31cc-8577-49d4-9ca0-6857cda6dc4d': 'createLocationBasedRestrictionOP',
  '4925677f-9d4e-4acf-b7f1-2154e0c43bc3': 'createTimeBasedRestrictionOP',
  '1a7c1ebe-cf35-49bd-bfa6-c29f9d9805b3': 'createIPRestrictionOP',
  '464b208e-77de-4d7e-95b7-0d29a668043f': 'updateLocationBasedRestrictionOP',
  '8c13546d-1160-4b98-ad24-3baaed0f50e7': 'updateTimeBasedRestrictionOP',
  'cabc3957-9393-4b72-908c-dbc05e04da63': 'updateIPRestrictionOP',
  'd712516e-434f-4bde-a19c-9c0aafb4ed12': 'unpairExtensionMobile',
  'c83584d0-ebc4-438c-a233-0d7b3f58ec53': 'unpairOrganizationPortalMobile',
  '017d5f49-2c53-4a85-a1a4-7eebf80de0e0': 'trustBrowserRequestedExtension',
  '53362e44-2c77-4501-b15d-4c2edcb23230': 'importLoginsRequestedExtension',
  '9c7845c1-9a43-4857-9e99-2d4b1714a0f3': 'rejectRequestSharingVaultToEmployeeExtension',
  '236189be-9ebd-4872-b5de-4bfaef720236': 'rejectRequestSharingVaultToGroupExtension',
  '2fa6f957-12d6-4d8e-a018-78aab6c22e36': 'restoreUserInOrganizationOP',
  'f1ed588f-975b-4bbe-83e9-e7a5c23e647f': 'deleteStagedAndInvitedEmployeesOP',
  'eb7dea42-df3a-44c6-808d-94b7fc46dde9': 'createEmployeeAccountSCIM',
  '263c44a2-2075-46bd-949d-aa828517a3d1': 'linkEmployeeAccountSCIM',
  'a2e18fcb-4d28-4f53-b2e3-24f03d0e188e': 'archiveEmployeeAccountSCIM',
  'e494db5c-b135-441c-8fa3-6e5ad94d05f8': 'activateEmployeeAccountSCIM',
  'f8b81b6c-0082-4ed8-916e-83350f932e89': 'permanentDeleteEmployeeAccountSCIM',
  '949b2f6e-bd1a-4074-862a-4bfd3c7a5cfa': 'updateEmployeeAccountEmailSCIM',
  'ed913ab4-3940-4124-ae15-4acffac6b9bc': 'updateEmployeeAccountNameSCIM',
  '4ddeeae7-b1e5-4eda-ac3f-10f598e40c35': 'updateEmployeeAccountLanguageSCIM',
  '71bb42f5-c6c6-4451-9ff8-0ccc6222dfcc': 'createGroupSCIM',
  'ca1c09db-b94a-44e8-b601-8edf64ac5926': 'deleteGroupSCIM',
  '78db0250-826e-4d6d-a42b-d72671083406': 'addEmployeeToGroupSCIM',
  '270bbb0f-ad49-401c-bd1d-8f9a80ae1ed1': 'removeEmployeeFromGroupSCIM',
  'aa05833f-811d-48c3-815a-63dd36849221': 'updateGroupNameSCIM',
  'cc078702-53c2-4f8d-9ab7-48cf368af8d2': 'moveLoginToPrivateKeychainOP',
  'ea743df4-2472-40f9-b3ff-ee68cb510350': 'moveSecureNoteToPrivateKeychainOP',
  '832ea0cb-10b0-4d7b-bd48-7faa1bc517ac': 'moveCreditCardToPrivateKeychainOP',
  'f235ab60-b35c-4da5-a88a-70d0253af5ad': 'loginSharingExpirationMessageExtensionMobile',
  '8c5dcc04-4608-4443-980f-a4e0b3732d34': 'secureNoteSharingExpirationMessageExtensionMobile',
  '839ae322-0926-461a-a091-5b65283f669e': 'creditCardSharingExpirationMessageExtensionMobile',
  '995b3ab9-5a04-404c-a926-10e819981a6f': 'bulkRejectMasterPasswordResetRequestOP',
  'c31b4667-aacf-47bf-9531-ec7301c17e16': 'trustedBrowserExpirationMessageConsumerExtension',
  '03686b5b-efc1-4aff-a4c3-c020bf933224': 'secondaryMobileDeviceConnected',
  'd8f19ec2-34ea-4473-aa9f-0c32ae30011a': 'requestPasswordDetailsDesktop',
  '74cf089f-da8d-4195-9ee9-a01d66a85a06': 'pairingWithDesktop',
  '62931388-7560-4cef-bd3e-9def55a54251': 'unlinkLoginFromGroupOP',
  '85c1c4ef-1781-428a-bf5f-5c71b238471d': 'unlinkSecureNoteFromGroupOP',
  '71d9908c-72d9-423e-aadb-dc63b3f34a86': 'unlinkCreditCardFromGroupOP',
  'fa0321c0-0afb-4dbf-9aef-983025986dce': 'unlinkLoginFromEmployeeOP',
  '3d5c811d-61fd-4503-8a94-28c99c2dbf46': 'unlinkSecureNoteFromEmployeeOP',
  '74d5c555-7484-4967-b21e-bde28d8561e1': 'unlinkCreditCardFromEmployeeOP',
  'a1fd0965-46bc-4af6-bd62-34032de76757': 'enableCanManageLoginsForGroupOP',
  '524ae2dd-47ec-47e1-a0c1-82090a924054': 'disableCanManageLoginsForGroupOP',
  'd923e3f2-455e-4b26-b56a-9689eec4d425': 'enableTrustDeviceForGroupOP',
  '34af097f-9d44-4311-bd30-b07d45ca694c': 'disableTrustDeviceForGroupOP',
  'c3ec599c-b7a9-4455-ba22-5057fe591124': 'enableViewAndCopyPolicyForGroupOP',
  'c723f36e-5909-4869-8c0b-1dc32e177839': 'disableViewAndCopyPolicyForGroupOP',
  '82cca993-49bd-41c7-9ce5-9d31a87be496': 'enableAddAndEditPolicyForGroupOP',
  '02e86cc8-8006-40d7-ac67-2ec2a8dd12fc': 'disableAddAndEditPolicyForGroupOP',
  'a4d86733-68f7-4e8d-8867-78c8260c2cdb': 'enableDeletePolicyForGroupOP',
  '46147b8b-98dc-4e78-ba0e-d05ba4d195f5': 'disableDeletePolicyForGroupOP',
  'e3286ddd-2fa0-44e7-8c43-06809b0c2930': 'enableIPRestrictionOP',
  '73387daf-893c-4325-a9a6-07efa433792c': 'enableTimeBasedRestrictionOP',
  '19112b97-5db4-459b-8b7c-5a8151eee2fb': 'enableLocationBasedRestrictionOP',
  'a0bd25e6-3473-4dc0-8a8e-fa21b9d1663d': 'disableIPRestrictionOP',
  '68039d53-929e-4082-b562-b0a65228d91e': 'disableTimeBasedRestrictionOP',
  '6deac10a-f1ba-4aff-9fc6-84714fac5c43': 'disableLocationBasedRestrictionOP',
  '9f60f94e-b765-4223-96f4-16b23fc4df32': 'deleteIPRestrictionOP',
  '875fec69-b003-4809-8ea3-f1be86002f8d': 'deleteTimeBasedRestrictionOP',
  '00413d0b-0a5d-4b20-8b39-3e12a547ac7c': 'deleteLocationBasedRestrictionOP',
  'd5883e6b-1d77-4ff2-bc9b-fae9320d074a': 'updateOrganizationNameOP',
  'bce0cd62-f50d-44a1-a6ed-cf027c444d70': 'updateOrganizationVatOP',
  '76c98912-7c91-4fdb-ba31-d4c856b76ea7': 'updateOrganizationBillingEmailOP',
  'bb665eec-76bd-4e68-8fed-9a652534baf9': 'updateOrganizationPhoneOP',
  '9922c718-35ae-49b2-a51e-0217fd77f468': 'updateOrganizationAddressOP',
  '94d60668-9f13-4b1e-8782-edc1e4d8c3d2': 'updateOrganizationZipOP',
  '7d1b9590-3781-4446-8e97-afca1f90d0d6': 'updateOrganizationCountryOP',
  'da685908-1ce5-496b-9ce3-8eee4dd68d02': 'updateOrganizationCityOP',
  'aac8564e-50f2-4272-b4a0-7bc7ab59edc0': 'enableTrustedDeviceForOrganizationOP',
  '4d954d28-c698-4672-8f83-fe263939fd5d': 'disableTrustedDeviceForOrganizationOP',
  'b96924d1-7af9-497f-bf3d-2cb25e09975b': 'enableExportLoginsForOrganizationOP',
  '5471de24-25c2-4526-a62b-3ceb38a1c9c7': 'disableExportLoginsForOrganizationOP',
  'd4458b75-b1e5-4728-b5a5-087460c4efcd': 'enableSkipSavingLoginsForOrganizationOP',
  'c87ce669-3c20-466a-9589-670ab28e58f0': 'disableSkipSavingLoginsForOrganizationOP',
  '656b9371-e91a-4656-aed9-ae6579a749a9': 'enableSecondaryDeviceSettingForOrganizationOP',
  '17f3cc2e-1d0a-4986-94e5-d8559a23da67': 'disableSecondaryDeviceSettingForOrganizationOP',
  'b6576ea4-953d-437a-b435-152c7d8a1b17': 'addVerifiedDomainOP',
  '1509de7b-c15a-47fb-960c-136690109fb1': 'deleteBulkVerifiedDomainOP',
  '430a7d12-328f-42cf-8b58-873478e3a6ed': 'requestTrustedPortalCommandOP',
  'c67ccc8a-1325-419a-9fe6-a76631427a22': 'approveTrustedPortalRequestedCommandOP',
  '0495b26d-4c22-48f4-81b9-17654bc8c48b': 'rejectTrustedPortalRequestedCommandOP',
  '55b70a38-a726-48e2-b30d-ebf9e4826102': 'moveLoginIntoGroupMobile',
  '0692bdb3-6f7e-4182-960d-2d1cb99ebc22': 'configureTwoFAMobile',
  '6ce07442-46ce-4ade-bcd0-17df67ac03c2': 'pairedSecondaryDeviceMobile',
  '907a6a80-2cd1-445e-b0da-9c2224989b5d': 'unpairedSecondaryDeviceMobile',
  'b812447a-8c72-4eb5-b6a4-2473c7179a2f': 'logoutFromSecondaryDeviceMobile',
  '09264c25-8199-4b3d-a44f-571f2ddf9f07': 'updatePasswordOfGroupLoginExtension',
  '16ea7dac-b433-4083-b0a7-10fecbbb78d9': 'updateUnsecuredDataOfGroupLoginExtension',
  '2a736842-8309-4802-845d-df173f788bf8': 'updatedSecureNoteInMyNoteExtension',
  '4ae3e156-801f-447b-bcfa-22d437516754': 'updatedSecureNoteInGroupExtension',
  '1b4739b2-4f6f-4325-9e34-8d7890da5d20': 'sendReportSiteExtension',
  '385d063f-4298-4fd8-b21e-0b684041c0dd': 'requestGroupPasswordDetailsDesktop',
  'd74f6ee3-fe72-4283-a396-6f305d63b5ca': 'editVaultCreditCardCommandExtension',
  '86a7f125-e047-445c-b204-fa20cf3a970b': 'editVaultSecureNoteCommandExtension',
  'fd5577b8-e5c7-4e22-b143-24ec6f8e8767': 'getVaultPasswordDetailsQueryExtension',
  '68c57674-217c-4ff6-a6d3-52141e9e5820': 'getVaultPasswordDetailsForGroupQueryExtension',
  'd9e653dc-510d-4415-960a-6b7a4e2098af': 'getSecureNoteDetailsQueryExtension',
  'f6e5286f-71fc-4b98-9f95-ed7b94bda79e': 'getSecureNoteDetailsForGroupQueryExtension',
  '991e6ebd-d9a9-4276-90f8-5f887097d146': 'logoutFromDesktopExtension',
  '85c86687-68ea-48a8-9301-e4f0eb667be1': 'employeeVaultSharingRequest',
  '811de21c-7d74-4a41-a0a4-56eac92d5559': 'employeeVaultSharingApprove',
  '141cd903-4534-495c-99bd-544b1b4362e7': 'employeeVaultSharingReject',
  '994bf9e3-8abd-4902-ad03-2a63d7312ea8': 'adminVaultSharingRequest',
  '8eedeb06-76e6-414d-a2b2-85137c0518e6': 'adminVaultSharingApprove',
  '9321afd3-5bda-48ec-a2ab-64e9affc019f': 'groupVaultSharingRequest',
  'd8ce68b2-bb26-456b-bc7b-68d09b4f9598': 'groupVaultSharingApprove',
  '894eae98-4414-4ee9-895a-8e276e7c4bfa': 'groupVaultSharingReject',
  '6d40976c-db85-40c5-ac0d-72b84d38623c': 'adminVaultSecureNoteCreationForEmployeeRequest',
  '8181bed3-f157-48c0-87f7-668644ef9309': 'adminVaultSecureNoteCreationForEmployeeApprove',
  '3d416776-07f9-4c14-b3ca-e15716c78f9c': 'adminVaultSecureNoteCreationForGroupRequest',
  'db6b3284-7e4e-4560-85b4-402606c00bc9': 'adminVaultSecureNoteCreationForGroupApprove',
  '9c469619-ac26-4dfe-ad96-00b8e2c87766': 'deletionForEmployeeLoginRequested',
  '028fd157-9dc3-4d5a-9d43-c84a25310756': 'deletionForEmployeeSecureNoteRequested',
  'c5ab58d3-769a-4dcb-85d8-a0d7e69d501e': 'deletionForEmployeeCreditCardRequested',
  'cced26db-113f-4aa7-a2c8-1a81c6a7a772': 'deletionForEmployeeLoginApproved',
  'bae2572b-1696-4fcc-bb13-05dc4465c8f7': 'deletionForEmployeeSecureNoteApproved',
  '662c9a5a-fc7c-4b99-80a3-baca7499cca4': 'deletionForEmployeeCreditCardApproved',
  '311bc3a0-503a-4de8-84d0-38a6e55d784b': 'deletionForEmployeeLoginRejected',
  '9c11c2fb-b43f-4e56-9c2b-c22101df4116': 'deletionForEmployeeSecureNoteRejected',
  'eb9d2d61-6f56-4d6b-a606-b527702e875d': 'deletionForEmployeeCreditCardRejected',
  '1b6bb96c-4d5a-4381-84d9-d3c07acf792d': 'deletionForGroupLoginRequested',
  '67c060cc-2626-4bb3-b5f3-f7bcf68850c5': 'deletionForGroupSecureNoteRequested',
  '4d82d739-9915-4f27-bac3-800c391147f2': 'deletionForGroupCreditCardRequested',
  'f1918936-ed8b-497b-8360-6f08cfa6dc1d': 'deletionForGroupLoginApproved',
  '6c304c7b-baf7-4fda-b8e7-88d39c23cf11': 'deletionForGroupSecureNoteApproved',
  '0ee2459d-6e92-44c3-a900-1a7f8a94f932': 'deletionForGroupCreditCardApproved',
  '4be42b64-b19b-4ecf-aba1-6c1f8fabfbe6': 'deletionForGroupLoginRejected',
  'a2b79d13-2fd6-4b47-a153-35b6083bfa73': 'deletionForGroupSecureNoteRejected',
  '1c64edb5-3f80-488a-a509-090175b0cc0b': 'deletionForGroupCreditCardRejected',
  '7d4b9718-8e2e-444a-81c2-002c1676bec1': 'exportLoginsFromOrganizationRequested',
  'da40b614-4aed-4f62-8d7b-a65ae9ea77bd': 'exportLoginsFromGroupRequested',
  '203cdc2b-77be-4dee-acc2-cf9a7fc193ea': 'exportLoginsFromOrganizationApproved',
  '56ece4bb-e603-4509-a91b-18aa9c4f5f0b': 'exportLoginsFromOrganizationRejected',
  'c5a4d0c4-6e87-48a9-9496-8a813475a2c3': 'exportLoginsFromGroupApproved',
  '1831af57-aa04-4d25-a8cd-cbc8412c9564': 'exportLoginsFromGroupRejected',
  '4450137d-4f51-43b3-bea4-10b58cbd8800': 'masterPasswordResetRequested',
  '5d7c16dd-f8cc-407a-9ee1-ec1fb2bad99d': 'masterPasswordResetApproved',
  '1e4b9822-b8f2-44f3-be03-dbc6e83bd650': 'moveLoginToEmployeeOrganizationKeychainRequest',
  'c5cd6001-ff32-429e-9340-5f56361aa5f9': 'moveSecureNoteToEmployeeOrganizationKeychainRequest',
  '14b91136-3ff1-4f83-abc7-343e476196ff': 'moveCreditCardToEmployeeOrganizationKeychainRequest',
  'f88d40b7-079d-4335-83d9-f85772261e04': 'moveLoginToEmployeeOrganizationKeychainApprove',
  'c66ef903-dcd0-4bb2-87a8-88953eb09117': 'moveSecureNoteToEmployeeOrganizationKeychainApprove',
  'ae5da4e1-584e-4c79-be9d-d1edc77af00c': 'moveCreditCardToEmployeeOrganizationKeychainApprove',
  '4d618d66-1e3f-4e0f-8e10-3caca63c442b': 'moveLoginToGroupOrganizationKeychainRequest',
  'd7b994c9-d0dd-4b95-b72b-d622abb8d0ce': 'moveSecureNoteToGroupOrganizationKeychainRequest',
  'c27ffd4d-a33a-45d3-a2bd-404f226acdd7': 'moveCreditCardToGroupOrganizationKeychain',
  '15cf987b-5a6e-402b-8729-6c9dc41de68c': 'moveLoginToGroupOrganizationKeychainApprove',
  '2e7bcd65-f44c-4a57-8572-ac597196233c': 'moveSecureNoteToGroupOrganizationKeychainApprove',
  '0434adbb-7319-4923-babd-dd9e526ca59b': 'moveCreditCardToGroupOrganizationKeychainApprove',
  'e5097623-0b46-4f0b-aa96-7ec5b48bf577': 'processEmployeeAsExistingUserRequest',
  '26a3bdca-6e2b-447c-b523-df9be6c39bda': 'processEmployeeAsExistingUserApprove',
  '5412a7e6-e275-49e6-a314-c59fa2719802': 'processFirstAdminAsExistingUserRequest',
  '8b8f1d7c-f235-42fa-ac95-a8002f1f6921': 'processFirstAdminAsExistingUserApprove',
  '4c6b67e9-1d93-4053-b999-1ce11c81ac4c': 'processInviteEmployeeRequest',
  '1b6c2121-00cc-40fe-9ea4-01f6dd085c34': 'processInviteEmployeeApprove',
  '3cc88450-39ef-472e-9e0c-06ad7c9af257': 'processNewEmployeeRequest',
  'c9ebd265-3c60-4228-b948-863c8134d29c': 'processNewEmployeeApprove',
  '3c272e1c-3294-46c1-a53c-2a649284a623': 'promoteAdminRequest',
  '9550a666-9811-49c0-9926-8e40f77f9343': 'promoteAdminApprove',
  '93c99b48-ecd8-49ac-a350-e83f4066af2f': 'promoteAdminReject',
  '6ecd02ef-c8ab-4321-a5d0-a2eae96dd4d1': 'revokeAdminRequest',
  'd3691808-ede4-46b1-8bc4-2a1a1fff921e': 'revokeAdminApprove',
  '7fa98d26-ee2a-4247-9264-504ea7725f97': 'revokeAdminReject',
  'de115993-cc4d-42d9-bf2d-896f640dcf8e': 'revokeSharedLoginRequested',
  'dd44503c-2f0c-43b5-9bba-2d7f3b2732ee': 'revokeSharedSecureNoteRequested',
  '29be4832-2362-47e5-a55c-1733bdde5471': 'revokeSharedCreditCardRequested',
  '96a6f6d4-eaec-47c4-ba1b-57fd390394d3': 'revokeSharedLoginApproved',
  'ddb7a3ef-ff08-4318-aadc-9b0edb293635': 'revokeSharedSecureNoteApproved',
  '197043ce-6dc7-4209-a43e-82f8d7ab5bfa': 'revokeSharedCreditCardApproved',
  '1ce5eb04-0620-4b78-88dd-1d8e3bcc7751': 'revokeSharedLoginRejected',
  'c80400ea-b4be-4440-9aaa-957bfab0dd5e': 'revokeSharedSecureNoteRejected',
  '479d33aa-1278-43bd-a35b-6483d887421c': 'revokeSharedCreditCardRejected',
  '4b005765-4a67-46e3-9503-969f41934d88': 'revokeLoginSharingRequested',
  'cc8d47c4-a7d0-4f84-8ae3-90e8eed2ceef': 'revokeSecureNoteSharingRequested',
  'a1c9b620-b594-49e4-99aa-bba9b0c2b6e1': 'revokeCreditCardSharingRequested',
  '16b1c433-9d46-43d4-bfa3-bb94c9aa65b7': 'revokeLoginSharingApproved',
  'b98f5def-838e-4fcf-95ab-1463ff521741': 'revokeSecureNoteSharingApproved',
  '19377108-da43-4719-8754-773ec5b43a27': 'revokeCreditCardSharingApproved',
  'ef46c247-d7f7-4051-a15d-03191321d06a': 'revokeLoginSharingRejected',
  '67e9e01b-5000-44e6-a82b-e11001038218': 'revokeSecureNoteSharingRejected',
  'a6d7e712-282c-42c5-968b-830eccaac51f': 'revokeCreditCardSharingRejected',
  '69d8d14f-c88b-4839-b73e-014a839de527': 'shareGroupSecretWithEmployeeAccountRequest',
  'ca730fcb-b5e7-40c8-9e5b-9b043c1eefdf': 'shareGroupSecretWithEmployeeAccountApprove',
  '92843da9-4e5d-49a5-b5bb-81915d50b9b8': 'creditCardCreationForEmployeeRequested',
  '8670fbed-933f-4d9f-8d02-6bbad7be0ce1': 'creditCardCreationForEmployeeApproved',
  'f641ca33-1456-43ff-b6f6-378c21ecfa81': 'creditCardCreationForEmployeeRequestedAdminRequested',
  'c1139a30-982c-416d-bbcf-163334cd9ee7': 'creditCardCreationForEmployeeRequestedAdminApproved',
  'c888cb54-cfec-4456-af9e-a76401ab0879': 'creditCardCreationForGroupRequested',
  '28bae03e-3b8f-48cf-baf0-568909b164e6': 'creditCardCreationForGroupRequestedApproved',
  '6ee9f315-b1ec-49f0-b1a6-fd745016202f': 'creditCardCreationForGroupRequestedAdminRequested',
  'c38ee204-5390-4419-983f-4fdfab083788': 'creditCardCreationForGroupRequestedAdminApproved',
  '1902514d-2bc7-4d5e-8c71-3d9b1c36c8aa': 'creditCardDetailsRequested',
  '02e7205c-08f3-469e-b494-1bdd63d5c0f0': 'creditCardDetailsApproved',
  '9f2aafb5-dc79-4689-9a33-4499fc75ba7b': 'creditCardDetailsRejected',
  '16b11e07-4963-4c41-8e8d-487e073585cd': 'creditCardDetailsRequestedForGroup',
  '343b7d4b-4852-4abd-87e4-4b304c092a38': 'creditCardDetailsApprovedForGroup',
  '900f060a-1578-4f91-a470-7e0ab4090f4f': 'creditCardDetailsRejectedForGroup',
  'f67e3114-6434-4ee9-9ee3-6bd24487b05c': 'creditCardEditRequestedForEmployee',
  '588c9ae4-eb33-4a0a-ba71-8fcf9f0b9b0d': 'creditCardEditApprovedForEmployee',
  '6d812084-a27f-480b-83fd-4d42730dc0d7': 'creditCardEditRequestedForGroup',
  '6a037c5b-906f-4a1b-b979-02ba99f45ea2': 'creditCardEditApprovedForGroup',
  '7376da0d-cd44-45c0-98e5-4eeedf99ac48': 'passwordCreationForEmployeeRequested',
  '5dae61d7-6121-46e4-88b0-1117b63ec7fb': 'passwordCreationForEmployeeApproved',
  '18174a21-61af-4469-981c-be3f38e221cf': 'passwordCreationForEmployeeRequestedAdmin',
  '92800479-1ea1-4369-af0a-3384b4fd953f': 'passwordCreationForEmployeeApprovedAdmin',
  '17a65898-a641-4ebb-a103-881344dc9765': 'passwordCreationForGroupRequested',
  '68dfe523-eb01-4f60-a02e-99f5968c225b': 'passwordCreationForGroupApproved',
  '1b13e098-3d76-4517-8b2a-77dddcee57d2': 'passwordCreationForGroupRequestedAdmin',
  '2ac2a9c0-d0e2-4818-8803-d782796042f0': 'passwordCreationForGroupApprovedAdmin',
  'daf12269-a58f-4e3a-ab01-05b1293a7cac': 'vaultPasswordDetailsRequested',
  'ad9bb07a-4011-4a96-a4b8-b4acc7b081a6': 'vaultPasswordDetailsApproved',
  '74a43121-e228-4e24-8674-76ad1ab38b08': 'vaultPasswordDetailsRejected',
  '33da5e0a-6b99-4f35-b518-2e33d96a353c': 'vaultPasswordDetailsRequestedForGroup',
  '0d9d0c7a-e4b3-4c87-96de-38b864ca4e96': 'vaultPasswordDetailsApprovedForGroup',
  '5444ed5f-0443-435b-9ea0-872f53db869d': 'vaultPasswordDetailsRejectedForGroup',
  'bef3e811-fc1d-4f94-af67-e6bb3d50e655': 'vaultPasswordEditRequestedForEmployee',
  '0106e095-abbf-45b6-bee2-35dfea893346': 'vaultPasswordEditApprovedForEmployee',
  '379bdf23-9ca4-4416-b86e-ac0fefc7ea43': 'vaultPasswordEditRequestedForGroup',
  '28c743d8-1571-48c5-a51e-747e31b4f132': 'vaultPasswordEditApprovedForGroup',
  '85e1a3d6-c476-4b1b-b411-51f729b0f72d': 'vaultSecureNoteCreationForEmployeeRequested',
  '7a03314b-75fb-431e-86cc-d442fc921d0d': 'vaultSecureNoteCreationForEmployeeApproved',
  '410926f4-b738-4caf-a158-a84888d73d65': 'vaultSecureNoteCreationForGroupRequested',
  '3b39a527-2a33-4ce8-b62f-a8def663e7c8': 'vaultSecureNoteCreationForGroupApproved',
  '5f395709-581f-4377-9163-33ef1bdefbcf': 'vaultSecureNoteDetailForGroupRequested',
  '376fa2c2-948d-414f-afae-6611f19aaa5c': 'vaultSecureNoteDetailForGroupApproved',
  '00db98f2-d88c-4838-b100-5d5ab2483679': 'vaultSecureNoteDetailForGroupRejected',
  'a3d33214-ff82-4ab1-9086-0f1256fa0c3f': 'vaultSecureNoteDetailsRequested',
  '37dfb124-8e59-4043-ac83-1278cf1a80c6': 'vaultSecureNoteDetailsApproved',
  '4a699f2d-b850-4ba8-8f12-2e2dd1acae50': 'vaultSecureNoteDetailsRejected',
  '4b38a933-1c7d-44bb-86b1-21b14b000c6e': 'vaultSecureNoteEditRequestedForEmployee',
  'db11786f-49cc-48f0-b176-4a58d59e1428': 'vaultSecureNoteEditApprovedForEmployee',
  '25758d49-f0ca-4c05-a1de-70670079e5a5': 'vaultSecureNoteEditRequestedForGroup',
  '0b69fe58-8eeb-4a1b-8232-54266d507190': 'vaultSecureNoteEditApprovedForGroup',
  '87d0cdc0-3893-4ba7-89ed-01f558822dde': 'vaultUnmanagedPasswordCreationRequested',
  '3505d4ab-f993-4adb-94d0-72f750f32adc': 'vaultUnmanagedPasswordCreationApproved',
  '3b73d88c-f89d-4b74-a224-be641d2ca140': 'vaultUnmanagedCreditCardCreationRequested',
  'f248cb96-0620-4274-9f69-bcc18d1955ae': 'vaultUnmanagedCreditCardCreationApproved',
  'cce21242-c4cb-4198-977a-10cbbb12be8b': 'vaultUnmanagedSecureNoteCreationRequested',
  'dc3bdb8a-5061-4257-8f0e-985871a39174': 'vaultUnmanagedSecureNoteCreationApproved',
  'ef175800-5a84-4d0a-89bc-a7e45b3b4115': 'rejectedImportExportOfLoginsAndTrustedBrowser',
  'cf218906-1a37-4b5d-8150-fe0310b234fe': 'approvedImportExportOfLoginsAndTrustedBrowser',
  '71bc9916-fa1c-42b0-af93-2b44c5c39476': 'sharePrivateKeys',
  'b3350ac0-e3c3-47f2-ac21-04c72d2a6f15': 'jumpToOrganizationPortal',
  '47c027a4-f224-4125-906d-ef8d1bcf8bd0': 'partnerOrganizationRequestAccess',
  '528e3f43-de2f-4ece-97f2-68daf2fd4ad8': 'partnerAccessRevoked',
  '8d2d65bc-12cd-43e2-8988-09895646b4a4': 'approvePartnerSupportRequestMobile',
  'cd84d92a-feee-4e17-93ae-7f88872c3c6a': 'createPartnerSupportRequest',
  '98604be3-63c7-4069-ac78-1adc2723f5a0': 'rejectPartnerSupportRequest',
  '2500080f-2a6e-4572-b2ec-113b1122619c': 'profileLanguageChangeMobile',
  '2a68c27e-c35d-4d79-8e0c-cce85bc99bc8': 'moveLoginFromMyDataToGroups',
  '8e26e26a-cc91-4a08-b9e2-b4c8ee6543c7': 'moveCardFromMyDataToGroups',
  '3b343837-9b90-423f-b9ca-13609a772530': 'moveNoteFromMyDataToGroups',
  'a9a1c0b4-b52e-4dc7-8f1a-4300073ffee4': 'exportLicenseDataRequestedForOrganizations',
  '8cea9aca-1b9d-4d29-9e78-e5cfc0150147': 'exportLicenseDataRequestedForPartnerOrganizations',
  '924f6760-6111-4a98-b843-111dfc75c37f': 'moveVaultPasswordToGroupOrganizationKeychain',
  'b23ff373-8f68-43d5-8117-57d978afd684': 'approveMoveVaultPasswordToGroup',
  '39136651-e537-440e-87c2-c09330a19771': 'moveVaultPasswordToGroup',
  '9ecade6e-f741-4974-95e5-a9b1334d404f': 'createCreditCardForEmployee',
  '830e8290-1da4-4573-9fd9-428aa11e2505': 'vaultShared',
  'eacfea4e-e34e-4eef-949e-eccc586b2181': 'moveVaultSecureNoteToEmployeeOrganizationKeychain',
  '1f2f8cea-f1f9-47b2-b1e5-51ad46cdf58f': 'profileLanguageChangeExtension',
  '15924d9d-a62a-4729-bb77-22acd40cf1b8': 'approveMoveVaultSecureNoteToGroup',
  '21c83d98-67d8-4332-b9d1-5e68d2cf20a9': 'moveVaultSecureNoteToGroup',
  '1d3752ad-3221-41c8-98e0-e3c65ebb56c2': 'moveVaultPasswordBetweenEmployeesRequested',
  '97bf74fa-8b54-41ab-b981-09675ba8b49f': 'moveVaultSecureNoteBetweenEmployeesRequested',
  '7fd2d28c-7052-48d2-898b-493ad1236b76': 'moveVaultCreditCardBetweenEmployeesRequested',
  '15a06874-a7c1-4dec-9316-7ccfebcf440d': 'moveVaultPasswordFromGroupToEmployeeRequested',
  '05501d87-d83c-4f4a-9a35-deef892f41d1': 'moveVaultSecureNoteFromGroupToEmployeeRequested',
  'bb7d1ae1-c485-484d-a52f-5340841aafb0': 'moveVaultCreditCardFromGroupToEmployeeRequested',
  '16c90ef9-9692-4811-8bff-7e410b6b626f': 'moveVaultPasswordBetweenGroupsRequested',
  '5d4b383a-8258-49b4-a1fe-d8d86366f83a': 'moveVaultSecureNoteBetweenGroupsRequested',
  '119659ee-6216-47ca-8213-7eb33477c25b': 'moveVaultCreditCardBetweenGroupsRequested',
  '0d8d4159-d4ed-45ee-b22b-bc417fddc1d6': 'moveVaultPasswordFromEmployeeToGroupRequested',
  '595df864-5d60-4b35-a29a-e2e0a7876bd1': 'moveVaultSecureNoteFromEmployeeToGroupRequested',
  '4e29d0da-e1c8-4e75-a113-adc21a30689a': 'moveVaultCreditCardFromEmployeeToGroupRequested',
  '4ea7ccd1-194b-433a-ba5f-46fbb9863fba': 'organizationsMovedConsumer',
};

export default AuditLogEventsNames;
